import React, { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import { updateDoc, doc, getDoc, setDoc } from "firebase/firestore";
import {
  Button,
  Modal,
  Select,
  Typography,
  MenuItem,
  Breadcrumbs,
  Link,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const UpdateAuto = () => {
  const { id } = useParams();
  const [data, setData] = useState({
    marka: "",
    model: "",
    vin: "",
    year: "",
    gos: "",
    drive: "",
    tireSeason: "",
    tireComment: "",
    tireFront: "",
    tireBack: "",
    statusAuto: "",
    stsNumber: "",
    stsOwner: "",
    stsDateStart: "",
    insuranceCompany: "",
    insuranceNumber: "",
    insuranceDateStart: "",
    insuranceDateEnd: "",
    inspectionСompany: "",
    inspectionDateStart: "",
    inspectionDateEnd: "",
    leasing: false,
    leasingTo: "",
    leasingBy: "",
  });

  const {
    marka,
    model,
    gos,
    drive,
    tireSeason,
    tireComment,
    tireFront,
    tireBack,
    statusAuto,
    vin,
    year,
    stsNumber,
    stsOwner,
    stsDateStart,
    insuranceCompany,
    insuranceNumber,
    insuranceDateStart,
    insuranceDateEnd,
    inspectionСompany,
    inspectionDateStart,
    inspectionDateEnd,
    leasing,
    leasingTo,
    leasingBy,
  } = data;

  useEffect(() => {
    id && getsingleAuto();
  }, [id]);

  const getsingleAuto = async () => {
    const docRef = doc(db, "auto", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setData({ ...data, [name]: checked });
  };

  const updateData = async () => {
    const docRef = doc(db, "auto", id);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
      await updateDoc(docRef, {
        marka: marka,
        model: model,
        gos: gos,
        drive: drive,
        year: year,
        vin: vin,
        tireComment: tireComment,
        tireSeason: tireSeason,
        tireFront: tireFront,
        tireBack: tireBack,
        statusAuto: statusAuto, // здесь убедитесь, что статус передается и обновляется
        stsNumber: stsNumber,
        stsOwner: stsOwner,
        stsDateStart: stsDateStart,
        insuranceCompany: insuranceCompany,
        insuranceNumber: insuranceNumber,
        insuranceDateStart: insuranceDateStart,
        insuranceDateEnd: insuranceDateEnd,
        inspectionСompany: inspectionСompany,
        inspectionDateStart: inspectionDateStart,
        inspectionDateEnd: inspectionDateEnd,
        leasing: leasing,
        leasingTo: leasingTo,
        leasingBy: leasingBy,
      });
    } else {
      await setDoc(docRef, {
        marka: marka,
        model: model,
        gos: gos,
        drive: drive,
        year: year,
        vin: vin,
        tireComment: tireComment,
        tireSeason: tireSeason,
        tireFront: tireFront,
        tireBack: tireBack,
        statusAuto: statusAuto,
        stsNumber: stsNumber,
        stsOwner: stsOwner,
        stsDateStart: stsDateStart,
        insuranceCompany: insuranceCompany,
        insuranceNumber: insuranceNumber,
        insuranceDateStart: insuranceDateStart,
        insuranceDateEnd: insuranceDateEnd,
        inspectionСompany: inspectionСompany,
        inspectionDateStart: inspectionDateStart,
        inspectionDateEnd: inspectionDateEnd,
        leasing: leasing,
        leasingTo: leasingTo,
        leasingBy: leasingBy,
      });
    }
    handleOpen(); // Показать уведомление об успешном обновлении
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Дашборд
            </Link>
            <Link underline="hover" color="inherit" href="/auto">
              Автомобили
            </Link>
            <Typography color="text.primary">
              <b>Создание автомобиля</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">{gos} - редактирование</p>
        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            className="btn-add-to-table"
            onClick={updateData}
          >
            Сохранить
          </Button>
          <Button
            variant="outlined"
            size="small"
            as={Link}
            to={"/orders"}
            className="btn-add-to-table"
          >
            Отменить
          </Button>
          <div className="drivers-block-btn"></div>
        </div>
        <div className="box-block-edit-params">
          <div className="box-create-form">
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <Select
                  className="select-update styles-select"
                  name="statusAuto"
                  value={statusAuto} // убедитесь, что статус автомобиля передается в компонент
                  size="small"
                  onChange={handleInputChange} // вызываем функцию для обновления состояния при изменении статуса
                >
                  <MenuItem value="Работает">Работает</MenuItem>
                  <MenuItem value="Простой">Простой</MenuItem>
                  <MenuItem value="Ремонт">Ремонт</MenuItem>
                  <MenuItem value="ДТП">ДТП</MenuItem>
                  <MenuItem value="На продаже">На продаже</MenuItem>
                  <MenuItem value="В Архив">В Архив</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <p className="p-big-update">Данные по автомобилю</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Марка:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="marka"
                  value={marka}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Модель:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="model"
                  value={model}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Гос.номер:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="gos"
                  value={gos}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Год.выпуска:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="year"
                  value={year}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">VIN номер:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="vin"
                  value={vin}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Привод:</p>
              </Grid>
              <Grid item xs={3}>
                <Select
                  className="select-update styles-select"
                  name="drive"
                  value={drive}
                  size="small"
                  onChange={handleInputChange}
                >
                  <MenuItem value="Задний">Задний</MenuItem>
                  <MenuItem value="Передний">Передний</MenuItem>
                  <MenuItem value="Полный">Полный</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <p className="p-big-update">Резина (размеры)</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Сезон:</p>
              </Grid>
              <Grid item xs={2}>
                <Select
                  className="select-update styles-select"
                  name="tireSeason"
                  value={tireSeason}
                  size="small"
                  onChange={handleInputChange}
                >
                  <MenuItem value="Лето">Лето</MenuItem>
                  <MenuItem value="Зима">Зима</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Передняя ось:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="tireFront"
                  value={tireFront}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Задняя ось:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="tireBack"
                  value={tireBack}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-for-input-update-auto-2-2">Примечание:</p>
              </Grid>
              <Grid item xs={3}>
                <textarea
                  className="input-update-auto"
                  type="text"
                  name="tireComment"
                  value={tireComment}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <p className="p-big-update">Свидетельство о регистрации ТС</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  СТС (серия и номер):
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="stsNumber"
                  value={stsNumber}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Собственник:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="stsOwner"
                  value={stsOwner}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={leasing}
                      onChange={handleCheckboxChange}
                      name="leasing"
                      color="primary"
                    />
                  }
                  label="Лизинг"
                />
              </Grid>
            </Grid>
            {leasing && (
              <>
                <Grid container spacing={2} className="grid-form-add">
                  <Grid item xs={2}>
                    <p className="text-for-input-update-auto-2-2">
                      Лизинг на кого:
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <input
                      className="input-update-auto"
                      type="text"
                      name="leasingTo"
                      value={leasingTo}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="grid-form-add">
                  <Grid item xs={2}>
                    <p className="text-for-input-update-auto-2-2">Лизинг по:</p>
                  </Grid>
                  <Grid item xs={2}>
                    <input
                      className="input-update-auto"
                      type="text"
                      name="leasingBy"
                      value={leasingBy}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <p className="p-big-update">ОСАГО страховой полис</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Страховая компания:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="insuranceCompany"
                  value={insuranceCompany}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Серия/номер полиса:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="insuranceNumber"
                  value={insuranceNumber}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Дата начала:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="insuranceDateStart"
                  value={insuranceDateStart}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Дата окончания:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="insuranceDateEnd"
                  value={insuranceDateEnd}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <p className="p-big-update">Диагностическая карта</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Где делали осмотр:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="inspectionСompany"
                  value={inspectionСompany}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Дата осмотра:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="inspectionDateStart"
                  value={inspectionDateStart}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Дата окончания:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="inspectionDateEnd"
                  value={inspectionDateEnd}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <CheckCircleIcon className="modal-grid-succes-icon" />
              <p className="modal-grid-succes-text">Данные изменены</p>
              <p className="modal-grid-succes-text">все отлично!</p>
            </Typography>
            <a href="/auto" className="modal-grid-succes-text-a">
              Вернуться в раздел Автомобили
            </a>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default UpdateAuto;
