import { Box, Button, Modal, Typography } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, query } from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import avatar from "../../assets/ava-driver.png";
import { FaCarBurst } from "react-icons/fa6";
import { IoIosCloudDone } from "react-icons/io";
import { GoLaw } from "react-icons/go";

const DamageList = () => {
  const usersCollectionRef = collection(db, "damages");
  const [drivers, setDrivers] = useState([]);
  const [filter, setFilter] = useState(null); // Начальное значение фильтра — null

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(query(usersCollectionRef));
      setDrivers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getUsers();
  }, []);

  const deleteDriver = async (id) => {
    const userDoc = doc(db, "drivers", id);
    await deleteDoc(userDoc);
    if (deleteDriver) {
      handleOpen();
    } else {
      alert("ошибка загрузки");
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const fetchTransactions = async () => {
    const q = query(collection(db, "damages"));
    const querySnapshot = await getDocs(q);
    let totals = {};
    querySnapshot.forEach((doc) => {
      const transactions = doc.data().transactions || [];
      const totalAmount = transactions.reduce(
        (sum, transaction) => sum + parseFloat(transaction.amountNew || 0),
        0
      );
      totals[doc.id] = totalAmount;
    });
    return totals;
  };

  const [totalAmounts, setTotalAmounts] = useState({});

  useEffect(() => {
    const loadData = async () => {
      const totals = await fetchTransactions();
      setTotalAmounts(totals);
    };
    loadData();
  }, []);

  function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const filteredDrivers = drivers.filter(
    (driver) =>
      formatNumber(driver.amount) !== formatNumber(totalAmounts[driver.id] || 0)
  );
  const archivedDrivers = drivers.filter(
    (driver) =>
      formatNumber(driver.amount) === formatNumber(totalAmounts[driver.id] || 0)
  );

  // Фильтрация на основе выбранного фильтра
  const filteredList = () => {
    if (filter === "osago") {
      return drivers.filter((driver) => driver.classeDamage === "Страховой");
    } else if (filter === "non-insured") {
      return filteredDrivers;
    } else if (filter === "archived") {
      return archivedDrivers;
    }
    return drivers;
  };

  const toggleFilter = (selectedFilter) => {
    setFilter((prevFilter) =>
      prevFilter === selectedFilter ? null : selectedFilter
    );
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <p className="header-mt f-roboto-900">ДТП / Повреждения</p>

        {/* Фильтры */}
        <div className="box-damage-container">
          <div
            className={`box-damage-block ${filter === "osago" ? "active" : ""}`} // Добавляем класс "active" если фильтр "osago"
            onClick={() => toggleFilter("osago")} // Переключаем фильтр ОСАГО
          >
            <GoLaw size="30" className="filter-icon-box" />
            <div>
              <p className="t-header-filter">ОСАГО, суды</p>
              <p className="t-mini-filter">ОСАГО, судебные дела</p>
            </div>
          </div>
          <div
            className={`box-damage-block ${
              filter === "non-insured" ? "active" : ""
            }`} // Добавляем класс "active" если фильтр "Не страховые"
            onClick={() => toggleFilter("non-insured")} // Переключаем фильтр Не страховые
          >
            <FaCarBurst size="30" className="filter-icon-box" />
            <div>
              <p className="t-header-filter">Не страховые</p>
              <p className="t-mini-filter">ДТП, повреждения</p>
            </div>
          </div>
          <div
            className={`box-damage-block ${
              filter === "archived" ? "active" : ""
            }`} // Добавляем класс "active" если фильтр "Архив"
            onClick={() => toggleFilter("archived")} // Переключаем фильтр Архив
          >
            <IoIosCloudDone size="30" className="filter-icon-box" />
            <div>
              <p className="t-header-filter">Архив</p>
              <p className="t-mini-filter">Закрытые дела</p>
            </div>
          </div>
        </div>

        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            className="btn-add-to-table"
            as={Link}
            to={"/add_damage"}
          >
            Добавить
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="btn-settings-table"
          >
            <SettingsIcon className="icon-setting" />
          </Button>
        </div>
        <table id="myTable">
          <thead>
            <tr>
              <th>Ф.И.О. водителя</th>
              <th>Гос.номер</th>
              <th className="center-table f-roboto-500b">Тип повреждения</th>
              <th className="center-table f-roboto-500b">Виновность</th>
              <th className="center-table f-roboto-500b">Сумма ущерба</th>
              <th className="center-table">Сколько выплатил</th>
              <th className="center-table">Комментарий</th>
              <th className=" th-right">Действия</th>
            </tr>
          </thead>
          <tbody>
            {filteredList().map((driver) => (
              <tr key={driver.id}>
                <td className="f-roboto-500">{driver.driver}</td>
                <td className="f-roboto-500">{driver.auto}</td>
                <td className="center-table f-roboto-500">
                  {driver.typeDamage}
                </td>
                <td className="center-table f-roboto-500">{driver.guilt}</td>
                <td className="rubles-simbol center-table f-roboto-500">
                  <b>{formatNumber(driver.amount)} ₽</b>
                </td>
                <td className="rubles-simbol center-table f-roboto-500">
                  {formatNumber(totalAmounts[driver.id] || 0)} ₽
                </td>
                <td className="comment-table f-roboto-500">{driver.comment}</td>
                <td className="end-actions-table">
                  <Link to={`/damage_view/${driver.id}`}>
                    <Button
                      variant="none"
                      size="small"
                      className="btn-icon-table"
                    >
                      <VisibilityIcon className="icon-table" />
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              <HighlightOffIcon className="modal-grid-delete-icon" />
              <p className="modal-grid-succes-text">Водитель успешно удален!</p>
            </Typography>
            <a href="/drivers" className="modal-grid-succes-text-a">
              Вернуться в раздел "Водители"
            </a>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default DamageList;
