import { Box, Breadcrumbs, Button, Chip, Modal, Typography } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { React, useEffect, useState } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link } from "react-router-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import logoAuto from "../../assets/mb_logo.webp";
import logoBMW from "../../assets/bmw_logo.webp";
import logoAudi from "../../assets/audi_logo.png";
import zzapIcon from '../../assets/zz.png'
import CarRentalIcon from '@mui/icons-material/CarRental';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import AgricultureIcon from '@mui/icons-material/Agriculture';

const AutoList = () => {
    const usersCollectionRef = collection(db, "auto");
    const [auto, setAuto] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const data = await getDocs(usersCollectionRef);
            setAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getUsers();
    }, []);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openList, setOpenList] = useState(false);
    const handleOpenList = () => setOpenList(true);
    const handleCloseList = () => setOpenList(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const styleGos = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const taskCollectionRef = collection(db, "tasks");
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const getTasks = async () => {
            const data = await getDocs(taskCollectionRef);
            setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getTasks();
    }, []);

    //Кол-во автомобиолей со статусом "Работает"
    const autoCollectionRef = collection(db, "auto");
    const [collectionSize, setCollectionSize] = useState(0);
    useEffect(() => {
        const fetchCollectionSize = async () => {
            const q = query(autoCollectionRef, where("statusAuto", "==", "Работает"));
            const snapshot = await getDocs(q);
            setCollectionSize(snapshot.size);
        };
        fetchCollectionSize();
    }, []);

    //Кол-во автомобиолей со статусом "Свободные"
    const FreeAutoCollectionRef = collection(db, "auto");
    const [collectionSizeFreeAuto, setCollectionSizeFreeAuto] = useState(0);
    useEffect(() => {
        const fetchCollectionSize = async () => {
            const q = query(FreeAutoCollectionRef, where("statusAuto", "==", "Простой"));
            const snapshot = await getDocs(q);
            setCollectionSizeFreeAuto(snapshot.size);
        };
        fetchCollectionSize();
    }, []);

    //Кол-во автомобиолей со статусом "В РЕМОНТЕ"
    const RepairCollectionRef = collection(db, "auto");
    const [collectionSizeRepair, setCollectionSizeRepair] = useState(0);
    useEffect(() => {
        const fetchCollectionSize = async () => {
            const q = query(RepairCollectionRef, where("statusAuto", "==", "Ремонт"));
            const snapshot = await getDocs(q);
            setCollectionSizeRepair(snapshot.size);
        };
        fetchCollectionSize();
    }, []);

    //Кол-во автомобиолей со статусом "ДТП"
    const CrashCollectionRef = collection(db, "auto");
    const [collectionSizeCrash, setCollectionSizeCrash] = useState(0);
    useEffect(() => {
        const fetchCollectionSize = async () => {
            const q = query(CrashCollectionRef, where("statusAuto", "==", "ДТП"));
            const snapshot = await getDocs(q);
            setCollectionSizeCrash(snapshot.size);
        };
        fetchCollectionSize();
    }, []);

    const [searchQuery, setSearchQuery] = useState('');

    // Обработчик изменения значения поискового запроса
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Фильтры по кнопкам статуса...
    const [statusFilter, setStatusFilter] = useState('');

    const handleFilter = (status) => {
        if (status === statusFilter) {
            setStatusFilter(''); // Если выбран тот же статус, сбрасываем фильтр
        } else {
            setStatusFilter(status); // Устанавливаем выбранный статус
        }
    };

    // Фильтрация данных и применение активного стиля
    const filteredData = auto.filter((item) => {
        const statusMatch = (!statusFilter || item.statusAuto === statusFilter) && item.statusAuto !== 'На продаже';
        const searchMatch = !searchQuery || item.gos.toLowerCase().includes(searchQuery.toLowerCase());
        return statusMatch && searchMatch;
    });

    //Формат ДАТЫ
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const checkDate = (date) => {
        const currentDate = new Date();
        const targetDate = new Date(date);
        const diffInDays = Math.floor((targetDate - currentDate) / (1000 * 60 * 60 * 24));
        return diffInDays <= 15;
    };

    const totalCars = 29;
    const carsFree = collectionSizeFreeAuto;
    const carsInRepair = collectionSizeRepair;
    const carsDtp = collectionSizeCrash;
    const parkUtilization = ((totalCars - carsInRepair - carsDtp - carsFree) / totalCars) * 100;

    const filteredAndWorkingAutos = filteredData.filter(auto => auto.statusAuto === 'Работает');
    const filteredAndFreeAutos = filteredData.filter(auto => auto.statusAuto === 'Простой');
    const filteredAndRepairAutos = filteredData.filter(auto => auto.statusAuto === 'Ремонт');
    const filteredAndDtpAutos = filteredData.filter(auto => auto.statusAuto === 'ДТП');

    return (
        <div className="grid">
            <Header />
            <div className='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link className="breadcrumbs-first" to="/" underline="hover" color="inherit">
                            Дашборд
                        </Link>
                        <Typography color="text.primary"><b>Автомобили</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">Автомобили</p>
                <div className="box-info-grid">
                    <button className={`box-info`}
                        onClick={() => handleOpenList(false)}
                    >
                        <AgricultureIcon className="box-info-icon" />
                        <div >
                            <p className="p-info">Загрузка парка:</p>
                            <p className="p-b-info">{parkUtilization.toFixed(0)}%</p>
                        </div>
                    </button>
                    <button
                        className={`box-info ${statusFilter === 'Работает' ? 'active' : ''}`}
                        onClick={() => handleFilter('Работает')}>
                        <CarRentalIcon className="box-info-icon" />
                        <div >
                            <p className="p-info">В работе:</p>
                            <p className="p-b-info">{collectionSize}</p>
                        </div>
                    </button>
                    <button
                        className={`box-info ${statusFilter === 'Простой' ? 'active' : ''}`}
                        onClick={() => handleFilter('Простой')}>
                        <CarCrashIcon className="box-info-icon free-auto " />
                        <div >
                            <p className="p-info">Свободные:</p>
                            <p className="p-b-info">{collectionSizeFreeAuto}</p>
                        </div>
                    </button>
                    <button
                        className={`box-info ${statusFilter === 'Ремонт' ? 'active' : ''}`}
                        onClick={() => handleFilter('Ремонт')}>
                        <CarRepairIcon className="box-info-icon repair-icon" />
                        <div >
                            <p className="p-info">В ремонте:</p>
                            <p className="p-b-info">{collectionSizeRepair}</p>
                        </div>
                    </button>
                    <button
                        className={`box-info ${statusFilter === 'ДТП' ? 'active' : ''}`}
                        onClick={() => handleFilter('ДТП')}
                    >
                        <TaxiAlertIcon className="box-info-icon crash-auto" />
                        <div>
                            <p className="p-info">ДТП (простой):</p>
                            <p className="p-b-info">{collectionSizeCrash}</p>
                        </div>
                    </button>
                </div>

                <div className="info-active-block-table">
                    <input type="text" className="search-input-auto" value={searchQuery} onChange={handleSearchChange} placeholder="Поиск по гос.номеру" />
                    <div className="drivers-block-btn"></div>
                    <div className="drivers-block-btn"></div>
                    <Button
                        variant="outlined"
                        size="small"
                        as={Link} to={'/addAuto'}
                        className="btn-add-to-table"
                    >
                        Добавить
                    </Button>
                </div>
                <table id="myTable">
                    <tbody>
                        <tr>
                            <th></th>
                            <th >Марка</th>
                            <th >Гос.номер</th>
                            <th >Резина</th>
                            <th >Cтатус</th>
                            <th>VIN номер</th>
                            <th>Zzap</th>
                            <th>ОСАГО (до)</th>
                            <th>ДК (до)</th>
                            <th>Привод</th>
                            <th>Год</th>
                            <th>Цвет</th>
                            <th>Собственник</th>
                            <th>Действия</th>
                        </tr>
                        {filteredData.map((auto) => (
                            <tr key={auto.id} >
                                <td className="tasks-flex ">
                                    <div className="table-date-text-gray">{auto.marka === "BMW" && <img src={logoBMW} alt="Logo" className="ava-img" /> || auto.marka === "Audi" && <img src={logoAudi} alt="Logo" className="ava-img" /> || <img src={logoAuto} alt="Logo" className="ava-img" />}</div>
                                    {tasks.map(tasks => {
                                        if (tasks.gos === auto.gos && tasks.statusTask !== 'Выполнено') {
                                            return (
                                                <p key={tasks.id} className="tasks-badge" />
                                            );
                                        }
                                    })}
                                </td>
                                <td>{auto.marka} {auto.model}</td>
                                <td>{auto.gos}</td>
                                <td>
                                    <p className="p-chip-tires-seasons" > {auto.tireSeason === "Зима" && <p className="chip-tires-seasons">❄️</p> || auto.tireSeason === "Лето" && <p className="chip-tires-seasons">☀️</p>}</p>
                                </td>
                                <td>
                                    <p className="chip-order-table-status"> {auto.statusAuto === "Ремонт" && <p className="chip-new_order">Ремонт</p> || auto.statusAuto === "Работает" && <p className="chip-succes_order">Работает</p> || auto.statusAuto === "Простой" && <p className="chip-wait_order">Свободна</p> || auto.statusAuto === "ДТП" && <p className="chip-dtp_order">ДТП</p>}</p>
                                </td>
                                <td >
                                    {auto.vin}
                                </td>
                                <td >
                                    <a href={`https://www.zzap.ru/public/catalogs/parts.aspx#/carInfo?q=${auto.vin}`} target="_blank" rel="noopener noreferrer">
                                        <img src={zzapIcon} alt="Image Description" className="iconZ" />
                                    </a>
                                </td>
                                <td>{/*{formatDate(auto.insuranceDateEnd)}г.*/}
                                    {
                                        checkDate(auto.insuranceDateEnd) ? (
                                            <span className="alert-end" >
                                                {formatDate(auto.insuranceDateEnd)}г.
                                            </span>
                                        ) : (
                                            <span>
                                                {formatDate(auto.insuranceDateEnd)}г.
                                            </span>
                                        )
                                    }
                                </td >
                                <td>
                                    {auto.inspectionDateEnd === "" && <p className="p-gray">Нет данных</p> || auto.inspectionDateEnd === undefined && <p className="p-gray">Нет данных</p> || auto.inspectionDateEnd === auto.inspectionDateEnd && <p>{formatDate(auto.inspectionDateEnd)}г.</p>}
                                </td>
                                <td>{auto.drive}</td>
                                <td>{auto.year}</td>
                                <td>{auto.color}</td>
                                <td>
                                    <Chip size="small" label={auto.stsOwner} variant="outlined" />
                                    {auto.leasing && (
                                        <p>Лизинг по {auto.leasingBy}</p>
                                    )}
                                </td>
                                <td className="td-table-end-action">
                                    <Link to={`/auto_view/${auto.id}`}
                                        value={auto.id}
                                        className="btn-icon-table">
                                        <VisibilityIcon className="icon-table" />
                                    </Link >
                                    <Link to={`/auto_update/${auto.id}`}
                                        value={auto.id}
                                        className="btn-icon-table"
                                    >
                                        <EditIcon className="icon-table" />
                                    </Link >
                                </td>
                            </tr >
                        ))}
                    </tbody >
                </table >
                <Modal
                    keepMounted
                    open={openList}
                    onClose={handleCloseList}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={styleGos} className="box-modal-succes">
                        <div>
                            <div>
                                <p className="t-h-modal-gos">Автомобили в работе:</p>
                                <div className="block-modal-gos" >
                                    {filteredAndWorkingAutos.map(auto => (
                                        <div key={auto.id}>
                                            <p className="t-mini-modal-gos">{auto.gos}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <p className="t-h-modal-gos mt-15">Свободные автомобили:</p>
                                <div className="block-modal-gos" >
                                    {filteredAndFreeAutos.map(auto => (
                                        <div key={auto.id}>
                                            <p className="t-mini-modal-gos">{auto.gos}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <p className="t-h-modal-gos mt-15">В ремонте:</p>
                                <div className="block-modal-gos" >
                                    {filteredAndRepairAutos.map(auto => (
                                        <div key={auto.id}>
                                            <p className="t-mini-modal-gos">{auto.gos}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <p className="t-h-modal-gos mt-15">ДТП:</p>
                                <div className="block-modal-gos" >
                                    {filteredAndDtpAutos.map(auto => (
                                        <div key={auto.id}>
                                            <p className="t-mini-modal-gos">{auto.gos}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <Typography className="modal-grid-succes" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <HighlightOffIcon className="modal-grid-delete-icon" />
                            <p className="modal-grid-succes-text">Автомобиль успешно удален!</p>
                        </Typography>
                        <Link to="/auto" className="modal-grid-succes-text-a">Отлично</Link>
                    </Box>
                </Modal>
            </div >
        </div >
    )
}

export default AutoList;
