import { React, useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./App.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase-config";

const Content = () => {
    const autoCollectionRef = collection(db, "auto");
    const [collectionSize, setCollectionSize] = useState(0);

    useEffect(() => {
        const fetchCollectionSize = async () => {
            const snapshot = await getDocs(autoCollectionRef);
            setCollectionSize(snapshot.size);
        };
        fetchCollectionSize();
    }, []);

    useEffect(() => {
        const fetchCollectionSize = async () => {
            const snapshot = await getDocs(autoCollectionRef).where("status", "==", "работает").get();;
            setCollectionSize(snapshot.size);
        };
        fetchCollectionSize();
    }, []);

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <p className="header-mt">Дашборд</p>
                <div className="grid-dashboard-menu">
                    <div className="card-dashboard">
                        <div className="card-dashboard-img-container">
                            <img alt="Logo" className="card-dashboard-img" />
                        </div>
                        <h3>Кол-во автомобилей {collectionSize}</h3>
                    </div>
                    <div className="card-dashboard">
                        <div className="card-dashboard-img-container">
                            <img alt="Logo" className="card-dashboard-img" />
                        </div>
                        <h3>Автомобили в ремонте</h3>
                    </div>
                    <div className="card-dashboard">
                        <div className="card-dashboard-img-container">
                            <img alt="Logo" className="card-dashboard-img" />
                        </div>
                        <h3>Задачи</h3>
                    </div>
                    <div className="card-dashboard">
                        <div className="card-dashboard-img-container">
                            <img alt="Logo" className="card-dashboard-img" />
                        </div>
                        <h3>Придумать</h3>
                    </div>
                </div>
                <p className="header-mt">Дашборд</p>
                <div className="grid-dashboard-menu">
                    <div className="card-dashboard">
                        <div className="card-dashboard-img-container">
                            <img alt="Logo" className="card-dashboard-img" />
                        </div>
                        <h3>Кол-во автомобилей {collectionSize}</h3>
                    </div>
                    <div className="card-dashboard">
                        <div className="card-dashboard-img-container">
                            <img alt="Logo" className="card-dashboard-img" />
                        </div>
                        <h3>Автомобили в ремонте</h3>
                    </div>
                    <div className="card-dashboard">
                        <div className="card-dashboard-img-container">
                            <img alt="Logo" className="card-dashboard-img" />
                        </div>
                        <h3>Задачи</h3>
                    </div>
                    <div className="card-dashboard">
                        <div className="card-dashboard-img-container">
                            <img alt="Logo" className="card-dashboard-img" />
                        </div>
                        <h3>Придумать</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;
