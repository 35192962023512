import {
  Box,
  Breadcrumbs,
  Button,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import avatar from "../../assets/ava-driver.png";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";

const DepositList = () => {
  const [deposits, setDeposits] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchDeposits = async () => {
      const querySnapshot = await getDocs(collection(db, "deposits"));
      const loadedDeposits = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        lastTransaction: doc.data().transactions[
          doc.data().transactions.length - 1
        ] || { amount: 0, comment: "" },
      }));
      setDeposits(loadedDeposits);
    };
    fetchDeposits();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Функция для вычисления общей суммы всех транзакций для одного депозита
  const calculateTotalAmount = (transactions) => {
    return transactions.reduce(
      (total, transaction) => total + transaction.amount,
      0
    );
  };

  // Функция для вычисления общей суммы всех депозитов
  const calculateTotalAllDeposits = () => {
    return deposits.reduce(
      (total, deposit) => total + calculateTotalAmount(deposit.transactions),
      0
    );
  };

  function formatNumber(num) {
    return new Intl.NumberFormat("ru-RU").format(num);
  }

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <a
              className="breadcrumbs-first"
              href="/"
              underline="hover"
              color="inherit"
            >
              Дашборд
            </a>
            <Typography color="text.primary">
              <b>Залоги</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <div>
          <p className="header-mt">Залоги</p>
        </div>
        <div className="box-info-grid">
          <div className="box-info all_deposit_widjet">
            <CurrencyRubleIcon className="box-info-icon" />
            <div>
              <p className="p-info">Всего залогов на:</p>
              <p className="p-b-info">
                {formatNumber(calculateTotalAllDeposits())} ₽
              </p>
            </div>
          </div>
        </div>
        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            className="btn-add-to-table"
            component={Link}
            to={"/add_deposit"}
          >
            Добавить
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="btn-settings-table"
          >
            <SettingsIcon className="icon-setting" />
          </Button>
          <div className="drivers-block-btn"></div>
        </div>

        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Активные" />
          <Tab label="Не активные" />
        </Tabs>

        {tabValue === 0 && (
          <table id="myTable">
            <thead>
              <tr>
                <th>Ф.И.О. водителя</th>
                <th className="center-table">Cтатус</th>
                <th className="center-table">Сумма залога</th>
                <th className="center-table">Сколько нужно залога</th>
                <th className="center-table">Действия</th>
              </tr>
            </thead>
            <tbody>
              {deposits
                .filter((deposit) => deposit.status === "Активный")
                .map((deposit) => (
                  <tr key={deposit.id}>
                    <td>{deposit.driver}</td>
                    <td>{deposit.status}</td>
                    <td className="center-table">
                      {formatNumber(calculateTotalAmount(deposit.transactions))}
                    </td>
                    <td className="center-table">
                      {formatNumber(deposit.totalDeposit)}
                    </td>
                    <td className="end-actions-table">
                      <Link to={`/deposit_view/${deposit.id}`}>
                        <Button
                          variant="none"
                          size="small"
                          className="btn-icon-table"
                        >
                          <VisibilityIcon className="icon-table" />
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}

        {tabValue === 1 && (
          <table id="myTable">
            <thead>
              <tr>
                <th>Ф.И.О. водителя</th>
                <th className="center-table">Cтатус</th>
                <th className="center-table">Сумма залога</th>
                <th className="center-table">Сколько нужно залога</th>
                <th className="center-table">Действия</th>
              </tr>
            </thead>
            <tbody>
              {deposits
                .filter((deposit) => deposit.status === "Не активный")
                .map((deposit) => (
                  <tr key={deposit.id}>
                    <td>{deposit.driver}</td>
                    <td>{deposit.status}</td>
                    <td className="center-table">
                      {formatNumber(calculateTotalAmount(deposit.transactions))}
                    </td>
                    <td className="center-table">
                      {formatNumber(deposit.totalDeposit)}
                    </td>
                    <td className="end-actions-table">
                      <Link to={`/deposit_view/${deposit.id}`}>
                        <Button
                          variant="none"
                          size="small"
                          className="btn-icon-table"
                        >
                          <VisibilityIcon className="icon-table" />
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DepositList;
