import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase-config";
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import GroupIcon from '@mui/icons-material/Group';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import GridViewIcon from '@mui/icons-material/GridView';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import ConstructionIcon from '@mui/icons-material/Construction';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

const Sidebar = () => {
    const autoCollectionRef = collection(db, "tasks");
    const [collectionSize, setCollectionSize] = useState(0);

    useEffect(() => {
        const fetchCollectionSize = async () => {
            const q = query(autoCollectionRef, where("statusTask", "==", "Новая"));
            const snapshot = await getDocs(q);
            setCollectionSize(snapshot.size);
        };
        fetchCollectionSize();
    }, []);

    return (
        <div className='sidebar'>
            <div className="logo-header">
                <div className="logo-div">
                    <p className="logo-t">AMCAR</p>
                </div>
            </div>
            <div className="sidebar-list">
                <p className="sidebar-blind-text">Продуктивность</p>
                <Link className="sidebar-list-d" to="/tasks">
                    <AutoStoriesIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">Задачи <b className="chip-task">{collectionSize > 0 && collectionSize}</b></span>
                </Link>
            </div>
            <div className="sidebar-list">
                <p className="sidebar-blind-text">Основные</p>
                <Link className="sidebar-list-d" to="/auto">
                    <LocalTaxiIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">Автомобили</span>
                </Link>
                <Link className="sidebar-list-d" to="/calendar_maintenance">
                    <CarRepairIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">Календарь ТО</span>
                </Link>
                <Link className="sidebar-list-d" to="/repair">
                    <ConstructionIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">Ремонты</span>
                </Link>
                <Link className="sidebar-list-d" to="/damage">
                    <CarCrashIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">ДТП / Ущерб </span>
                </Link>
                <Link className="sidebar-list-d" to="/deposits">
                    <CurrencyRubleIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">Залоги</span>
                </Link>
                <p className="sidebar-blind-text">Справочники</p>
                <Link className="sidebar-list-d" to="/drivers">
                    <GroupIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">Водители</span>
                </Link>
                <Link className="sidebar-list-d" to="/drivers">
                    <EmojiTransportationIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">Собственники</span>
                </Link>
                <p className="sidebar-blind-text">Хранилище</p>
                <Link className="sidebar-list-d" to="/drivers">
                    <GroupIcon className="sidebar-icon" color="primary" />
                    <span className="sidebar-list-a">Диск (разработка)</span>
                </Link>
            </div>
        </div>
    );
};

export default Sidebar;
